// import { useLastTrade } from '@/hooks/useLastTrade';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

// export enum NavbarMode {
//   Down = 'down',
//   Default = 'default',
//   Up = 'up'
// }

export type NavbarMode = 'down' | 'default' | 'up';
export interface LogoProps {
  className?: string;
  style?: React.CSSProperties;
  width?: number;
  animate?: boolean;
  forceLight?: boolean;
}
export default function Logo({
  width = 50,
  className,
  animate = false,
  style = {},
  forceLight = false
}: LogoProps) {
  const { resolvedTheme: theme } = useTheme();

  const colorDark = '#ffffff';
  const colorLight = '#000000';

  // const lastTrade = useLastTrade('SPY');
  // const greenThreshold = 0.0;

  const [navbarMode, setModeDebounced] = useState<NavbarMode>('default');
  const [value] = useDebounce(navbarMode, 20000, { leading: true });
  const [showLogo, setShowLogo] = useState<boolean>(false);
  useEffect(() => {
    setShowLogo(true);
  }, []);

  // useEffect(() => {
  //   if (!lastTrade?.todayPercent) {
  //     setModeDebounced('default');
  //   } else if (lastTrade?.todayPercent >= greenThreshold) {
  //     setModeDebounced('up');
  //   } else if (lastTrade?.todayPercent < greenThreshold) {
  //     setModeDebounced('down');
  //   } else {
  //     setModeDebounced('default');
  //   }
  // }, [greenThreshold, lastTrade, setModeDebounced]);

  if (!showLogo) {
    return null;
  }

  if (!forceLight && theme == 'light') {
    return (
      <svg
        // width="806"
        // height="806"
        viewBox="0 0 806 806"
        fill="none"
        style={{ ...style, width: `${width}px`, height: 'auto' }}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M403.499 747.5C593.758 747.5 748 593.262 748 403.001C748 212.738 593.758 58.5 403.499 58.5C213.237 58.5 59 212.738 59 403.001C59 593.262 213.237 747.5 403.499 747.5ZM414.707 738.062C593.582 738.062 738.584 593.054 738.584 414.181C738.584 235.306 593.582 90.3001 414.707 90.3001C235.833 90.3001 90.8279 235.306 90.8279 414.181C90.8279 593.054 235.833 738.062 414.707 738.062Z"
          fill="url(#paint1_linear_1134_21)"
        />
        <path
          d="M351.646 242.571H245.488L241.008 277.476H360.607L351.646 242.571Z"
          fill="black"
        />
        <path
          d="M235.493 309.615L228.255 350.74H378.529L368.189 309.615H235.493Z"
          fill="black"
        />
        <path
          d="M600.84 309.27H467.454L444.361 351.431H594.98L600.84 309.27Z"
          fill="black"
        />
        <path
          d="M462.628 453.034H327.173L338.203 494.159H438.157L462.628 453.034Z"
          fill="black"
        />
        <path
          d="M278.921 563.277C252.381 563.392 197.924 563.553 192.409 563.277L200.681 525.262H284.091L278.921 563.277Z"
          fill="black"
        />
        <path
          d="M559.479 562.931H475.036L480.206 524.916L566.028 526.644L559.479 562.931Z"
          fill="black"
        />
        <path
          d="M385.423 382.189H311.664L321.659 421.931H396.107L385.423 382.189Z"
          fill="black"
        />
        <path
          d="M589.811 382.534H505.713L497.785 423.314H582.228L589.811 382.534Z"
          fill="black"
        />
        <path
          d="M606.008 279.203L485.031 277.13L505.711 244.99H612.902L606.008 279.203Z"
          fill="black"
        />
        <path
          d="M426.782 380.806L402.656 421.931H479.172L500.886 380.806H426.782Z"
          fill="black"
        />
        <path
          d="M485.031 494.159L492.959 454.071H576.023L569.475 494.159H485.031Z"
          fill="black"
        />
        <path
          d="M205.162 494.159H290.295L295.809 454.071H211.366L205.162 494.159Z"
          fill="black"
        />
        <path
          d="M216.191 421.931H300.635L308.907 380.806H222.74L216.191 421.931Z"
          fill="black"
        />
        <path
          d="M350.611 559.475L343.029 525.262H421.268L402.656 559.475H350.611Z"
          fill="black"
        />
        <defs>
          <filter
            id="filter0_f_1134_21"
            x="35"
            y="34.5"
            width="737"
            height="737"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="12"
              result="effect1_foregroundBlur_1134_21"
            />
          </filter>
          <linearGradient
            id="paint0_linear_1134_21"
            x1="403.901"
            y1="58.5"
            x2="581.12"
            y2="669.768"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#00D1FF" />
            <stop offset="0.163767" stop-color="#00D930" />
            <stop offset="0.39065" stop-color="#00A1FB" />
            <stop offset="1" stop-color="#6100FF" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1134_21"
            x1="403.901"
            y1="58.5"
            x2="581.12"
            y2="669.768"
            gradientUnits="userSpaceOnUse"
          >
            <stop />
            <stop offset="0.163767" />
            <stop offset="0.39065" />
            <stop offset="1" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width="157"
      height="157"
      className={`${className} ${animate ? '' : ''}`}
      viewBox="0 0 157 157"
      fill="none"
      style={{ ...style, width: `${width - 5}px`, height: 'auto' }}
    >
      <path
        x={100}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.4673 156.143C121.485 156.143 156.359 121.27 156.359 78.2523C156.359 35.2341 121.485 0.361084 78.4673 0.361084C35.4492 0.361084 0.576172 35.2341 0.576172 78.2523C0.576172 121.27 35.4492 156.143 78.4673 156.143ZM81.0014 154.009C121.445 154.009 154.23 121.223 154.23 80.78C154.23 40.3367 121.445 7.55103 81.0014 7.55103C40.5582 7.55103 7.77246 40.3367 7.77246 80.78C7.77246 121.223 40.5582 154.009 81.0014 154.009Z"
        className={`${animate ? 'animate-spin origin-center' : ''}	`}
        fill="url(#paint0_linear_1006_59)"
      />
      <path
        d="M70.8123 45.248H48.9146L47.9904 52.4288H72.6606L70.8123 45.248Z"
        fill={colorDark}
      />
      <path
        d="M46.8529 59.0407L45.3598 67.501H76.3575L74.2247 59.0407H46.8529Z"
        fill={colorDark}
      />
      <path
        d="M122.214 58.9695H94.7004L89.9369 67.6433H121.006L122.214 58.9695Z"
        fill={colorDark}
      />
      <path
        d="M93.705 88.5454H65.7642L68.0393 97.0058H88.6571L93.705 88.5454Z"
        fill={colorDark}
      />
      <path
        d="M55.8108 111.225C50.3365 111.249 39.1033 111.282 37.9658 111.225L39.6721 103.404H56.8773L55.8108 111.225Z"
        fill={colorDark}
      />
      <path
        d="M113.683 111.154H96.2643L97.3308 103.333L115.034 103.689L113.683 111.154Z"
        fill={colorDark}
      />
      <path
        d="M77.7795 73.9708H62.565L64.6267 82.1468H79.9834L77.7795 73.9708Z"
        fill={colorDark}
      />
      <path
        d="M119.94 74.0419H102.592L100.957 82.4312H118.375L119.94 74.0419Z"
        fill={colorDark}
      />
      <path
        d="M123.281 52.7842L98.3262 52.3577L102.592 45.7457H124.703L123.281 52.7842Z"
        fill={colorDark}
      />
      <path
        d="M86.3109 73.6864L81.3342 82.1468H97.1175L101.597 73.6864H86.3109Z"
        fill={colorDark}
      />
      <path
        d="M98.3262 97.0058L99.9614 88.7587H117.095L115.745 97.0058H98.3262Z"
        fill={colorDark}
      />
      <path
        d="M40.5964 97.0058H58.157L59.2945 88.7587H41.8761L40.5964 97.0058Z"
        fill={colorDark}
      />
      <path
        d="M42.8714 82.1468H60.2899L61.9962 73.6864H44.2222L42.8714 82.1468Z"
        fill={colorDark}
      />
      <path
        d="M70.5988 110.443L69.0347 103.404H85.1734L81.3342 110.443H70.5988Z"
        fill={colorDark}
      />
      <defs>
        {value == 'down' ? (
          <linearGradient
            id="paint0_linear_1006_59"
            x1="78.5582"
            y1="0.361084"
            x2="118.627"
            y2="138.568"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF0000" />
            <stop offset="0.163767" stopColor="#D90041" />
            <stop offset="0.689818" stopColor="#A30062" />
            <stop offset="1" stopColor="#FF0000" stopOpacity="0" />
          </linearGradient>
        ) : (
          <linearGradient
            id="paint0_linear_1006_59"
            x1="78.4673"
            y1="0.361084"
            x2="118.536"
            y2="138.568"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00FFA3" />
            <stop offset="0.163767" stopColor="#00D930" />
            <stop offset="0.689818" stopColor="#00A1FB" />
            <stop offset="1" stopColor="#6100FF" stopOpacity="0" />
          </linearGradient>
        )}
      </defs>
    </svg>
  );
}
