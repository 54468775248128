import { Code, Filter, Home, List, MessagesSquare } from 'lucide-react';

export interface NavigationItem {
  key: string;
  name: string;
  href: string;
  userHref?: string;
  icon: React.ElementType;
  beta?: boolean;
  dev?: boolean;
  user?: boolean;
  active?: boolean;
  paths?: RegExp[];
  highlight?: boolean;
}
export const navigationItems = {
  main: [
    {
      name: 'Markets',
      key: 'markets',
      href: '/home',
      icon: Home,
      paths: [/\/m\/.*/],
      hidden_mobile: true
    },
    // {
    //   name: 'News',
    //   key: 'news',
    //   href: '/news',
    //   icon: Newspaper,
    //   hidden_mobile: false
    // },
    {
      name: 'Screener',
      key: 'screener',
      href: '/screener',
      icon: Filter,
      hidden_mobile: true
    },

    // {
    //   name: 'Portfolio',
    //   key: 'portfolio',
    //   href: '/l/portfolio',
    //   userHref: '/portfolios',
    //   icon: DollarSign
    // },
    // {
    //   name: 'Signals',
    //   key: 'notifications',
    //   href: '/notifications',
    //   // userHref: '/notifications',
    //   icon: Radar
    // },
    // {
    //   name: 'Actions',
    //   key: 'Actions',
    //   href: '/beta/actions',
    //   icon: Play
    // },
    // { name: 'Connections', key: 'connections', href: '/connections', icon: CloudSync, beta: true },
    // { name: 'Calendar', key: 'calendar', href: '/calendar', icon: Calendar },
    // {
    //   name: 'Notifications',
    //   key: 'notifications',
    //   href: '/profile/notifications',
    //   icon: Bell,
    //   user: true
    // },
    { name: 'Lists', key: 'list', href: '/lists', icon: List },
    // {
    //   name: 'Onboarding',
    //   key: 'onboarding',
    //   href: '/lists/requests',
    //   icon: Ship
    // }
    {
      name: 'API',
      key: 'api',
      href: '/docs/api',
      icon: Code,
      hidden_mobile: true
    },
    // {
    //   name: 'Help',
    //   key: 'help',
    //   href: '/help',
    //   icon: MessageCircleQuestion
    // },
    { name: 'Chat', key: 'chat', href: '/chat', icon: MessagesSquare }
    // {
    //   name: 'Submit Company',
    //   key: 'submit',
    //   href: '/beta/requests',
    //   icon: Send,
    //   user: true
    // }
  ] as NavigationItem[]
  // beta: [
  //   {
  //     name: 'Reports',
  //     key: 'Reports',
  //     href: '/beta/reports',
  //     icon: File
  //   }
  // ],

  // about: [
  // {
  //   name: 'Twitter',
  //   key: 'twitter',
  //   href: 'https://twitter.com/markets_sh',
  //   icon: Twitter
  // },
  // {
  //   name: 'Discord',
  //   key: 'discord',
  //   href: 'https://discord.gg/2NdGRDrFTf',
  //   icon: Discord
  // },
  // // { name: 'Whales', key: 'whales', href: '/whales', icon: Fishing, beta: true }
  // {
  //   name: 'Changelog',
  //   key: 'changelog',
  //   href: '/changelog',
  //   icon: GitBranch
  // },
  // // { name: 'Blog', key: 'blog', href: '/blog', icon: Www },
  // {
  //     name: 'About',
  //     key: 'about',
  //     href: '/',
  //     icon: Globe
  //   }
  // ] as NavigationItem[],
  // dev: [
  //   {
  //     name: 'Reports',
  //     key: 'reports',
  //     href: '/internal/report_dashboard',
  //     icon: BarChart3
  //   },
  //   {
  //     name: 'Onboarding',
  //     key: 'onboarding',
  //     href: '/internal/onboarding',
  //     icon: BarChart3
  //   }
  // ] as NavigationItem[]
};

// export const tabbarItems = [
//   { name: 'Markets', href: '/', icon: Home },
//   { name: 'Watchlist', href: '/watchlist', icon: List },
//   { name: 'Portfolios', href: '/portfolios', icon: DollarSign },
//   { name: 'Calendar', href: '/calendar', icon: Calendar }
// ] as NavigationItem[];

export const userNavigationItems = [
  // { name: 'Your Profile', href: `/u/profile` },
  { name: 'Settings', key: 'settings', href: '/settings' },

  {
    name: 'Portfolio',
    key: 'portfolio',
    href: '/portfolios'
  },
  { name: 'Posts', key: 'settings', href: '/posts', dev: true },
  {
    name: 'Signals',
    key: 'notifications',
    href: '/notifications'
  },
  { name: 'Teams', key: 'teams', href: '/profile/teams' },
  { name: 'Push Test', key: 'push_test', href: '/internal/pushes', dev: true },
  { name: 'Actions', key: 'push_test', href: '/beta/actions' },
  {
    name: 'Reports',
    key: 'reports',
    href: '/internal/report_dashboard',
    // icon: BarChart3
    dev: true
  },
  {
    name: 'Onboarding',
    key: 'onboarding',
    href: '/internal/onboarding',
    // icon: BarChart3
    dev: true
  },
  {
    name: 'Vector Search',
    key: 'vector_search',
    href: '/internal/search/vectors',
    // icon: BarChart3
    dev: true
  },
  {
    name: 'Passwords',
    key: 'passwords',
    href: '/internal/password',
    dev: true
  },
  {
    name: 'Sign Out',
    key: 'signout',
    href: '#'
  }
] as NavigationItem[];
