import { deepMerge } from '@/utils/object';
import { supabase } from '@/utils/supabase-client';
import { proxy, useSnapshot } from 'valtio';

// Define the structure of your UI state
interface UIState {
  sidebar_pinned: boolean;
  lists: {
    statistics_open: boolean;
  };
  info_panel: {
    open: boolean;
    content_id: string | null;
    content_type: string | null;
  };
  chat: {
    sidebar_open: boolean;
  };
}

const initialState: UIState = {
  sidebar_pinned: false,
  lists: {
    statistics_open: true
  },
  info_panel: {
    open: false,
    content_id: null,
    content_type: null
  },
  chat: {
    sidebar_open: false
  }
};

const store = proxy({
  data: initialState,
  async update(key: string, value: any) {
    // Optimistically update local state
    this.data = deepMerge({}, this.data, { [key]: value });

    try {
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      const userId = sessionData.session?.user?.id;
      if (!userId) throw new Error('No user ID found');

      // Fetch current server state
      const { data: userData, error: fetchError } = await supabase
        .from('users')
        .select('ui_state')
        .eq('id', userId)
        .single();

      if (fetchError) throw fetchError;

      // Merge new value with existing server state
      const updatedState = deepMerge({}, userData.ui_state, { [key]: value });

      // Update server state
      const { error: updateError } = await supabase
        .from('users')
        .update({ ui_state: updatedState })
        .eq('id', userId)
        .single();

      if (updateError) throw updateError;
    } catch (error) {
      console.error('Failed to update server state:', error);
      // Revert local state if server update fails
      this.data = deepMerge({}, this.data, { [key]: undefined });
    }
  }
});

export function useUiStore() {
  return useSnapshot(store.data);
}

export default store;
