import FilterTable from '@/components/ui/Tables/FilterTable';
import cn from 'classnames';
import dayjs from 'dayjs';
import { DownloadIcon } from 'lucide-react';
import { Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  formatNumberWithSuffix,
  formatPercent,
  isNumber,
  parseFloatSafe
} from './numberFormat';

const ignoreFacts = ['error'];
const jsonRank = ['summary', 'general'];

export function findArrayInObject(obj) {
  // Check if the input is an object
  if (typeof obj !== 'object' || obj === null) {
    return null;
  }

  // Iterate through the properties of the object
  for (let key in obj) {
    if (Array.isArray(obj[key])) {
      // Return the object if a property with an array is found
      return obj;
    }
  }

  // Return null if no property with an array is found
  return null;
}
export function sortArrayByPriority(array, sorted, key = null) {
  if (!sorted || !Array.isArray(array) || sorted.length === 0) {
    return array;
  }

  return array.sort((a, b) => {
    const aValue = key ? a[key] : a;
    const bValue = key ? b[key] : b;

    const aIndex = sorted.indexOf(aValue);
    const bIndex = sorted.indexOf(bValue);

    // If neither a nor b are in the sorted list, compare them alphabetically
    if (aIndex === -1 && bIndex === -1) {
      return String(aValue).localeCompare(String(bValue));
    }

    // Items not in the sorted list are pushed to the end
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;

    // Compare based on position in the sorted list
    return aIndex - bIndex;
  });
}

function downloadCSV(jsonArray, filename = 'export.csv') {
  // Convert JSON array to CSV string
  const header = Object.keys(jsonArray[0]).join(';');
  const csvRows = jsonArray.map((row) => Object.values(row).join(';'));
  csvRows.unshift(header);
  const csvString = csvRows.join('\r\n');

  // Create a Blob with the CSV string
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  // Create a link element, set the download attribute to the desired file name
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  // Append the link to the body, trigger the click event, and remove the link
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export const renderArrayTable = (arrayItems) => {
  const filtered = arrayItems.filter(
    (item) => Object.values(item).join('') !== ''
  );
  if (!filtered) {
    return null;
  }
  const wrapped = Array.isArray(filtered) ? filtered : [filtered];
  if (wrapped.length === 0 || wrapped[0] === null) {
    return null;
  }
  const keys = Object.keys(wrapped[0]); // assuming all objects have same structure

  const columns = keys.map((key) => ({
    header: key,
    accessorKey: key,
    enableHiding: true,
    enableGrouping: true
  }));
  if (filtered?.length > 10) {
    return (
      <div className="w-full  rounded overflow-x-auto bg= ">
        <div className="py-2 px-2 flex flex-row justify-end print:hidden">
          <button
            className="btn-default secondary small"
            onClick={() => downloadCSV(filtered)}
          >
            <DownloadIcon size={15} />
          </button>
        </div>
        <FilterTable
          columns={columns}
          data={filtered}
          initialPagination={{
            pageIndex: 0,
            pageSize: 100000
          }}
          showPagination={false}
        />
      </div>
    );
  }
  return (
    <div className="w-full  rounded overflow-x-auto bg= ">
      <div className="py-2 px-2 flex flex-row justify-end print:hidden">
        <button
          className="btn-default secondary small"
          onClick={() => downloadCSV(filtered)}
        >
          <DownloadIcon size={15} />
        </button>
      </div>
      <table className="table-auto text-xs w-full h-full divide-y divide-line ">
        <thead className="">
          <tr className="">
            {keys
              .filter((key) => !ignoreFacts.includes(key))
              .map((key, i) => (
                <th
                  key={i}
                  className={cn(
                    'py-3.5 px-2 capitalize font-semibold text-left whitespace-nowrap',
                    tableEntryFormattingClasses(wrapped?.[0]?.[key])
                  )}
                >
                  {key.replaceAll('_', ' ')}
                </th>
              ))}
          </tr>
        </thead>
        <tbody className="">
          {wrapped.map((item, i) => (
            <tr
              key={i}
              className={cn({
                'bg-gray-50 dark:bg-neutral-900 ': i % 2 != 0
              })}
            >
              {keys
                .filter((key) => !ignoreFacts.includes(key))
                .map((key, j) => (
                  <td
                    key={`t-${key}-${j}`}
                    className={cn('py-3 px-2', {
                      [tableEntryFormattingClasses(item[key])]: true
                    })}
                  >
                    {renderTableEntry(item[key], key)}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const tableEntryFormattingClasses = (item) => {
  // if (isNumber(item)) {
  //   return 'text-right ';
  // }
  return 'text-left ';
};

const extractDomainOrId = (url) => {
  try {
    // Remove http(s):// and www.
    let cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/, '');

    // Special handling for common platforms
    if (cleanUrl.includes('linkedin.com/')) {
      // Extract LinkedIn ID/username
      const match = cleanUrl.match(/linkedin\.com\/(in\/|company\/)([^\/\?]+)/);
      return match ? match[2] : cleanUrl;
    }

    if (cleanUrl.includes('crunchbase.com/')) {
      // Extract Crunchbase organization/person name
      const match = cleanUrl.match(
        /crunchbase\.com\/(organization|person)\/([^\/\?]+)/
      );
      return match ? match[2] : cleanUrl;
    }

    // For other URLs, just return the domain and first path segment if it exists
    const domainAndPath = cleanUrl.split('/');
    return domainAndPath[1]
      ? `${domainAndPath[0]}/${domainAndPath[1]}`
      : domainAndPath[0];
  } catch (e) {
    return url; // Return original if parsing fails
  }
};

const formatUrl = (item) => {
  if (typeof item === 'string' && item.includes('http')) {
    const displayText = extractDomainOrId(item);
    return (
      <a
        className="text-blue-500 hover:text-blue-400 underline"
        href={item}
        target="_blank"
        rel="noopener noreferrer"
      >
        {displayText}
      </a>
    );
  }
  return item;
};

export const renderTableEntry = (item, key) => {
  if (typeof item == 'object') {
    return Object.values(Object).join('-');
  }
  if (key?.toLowerCase()?.includes('date')) {
    const parsed = dayjs(item);
    if (parsed.isValid()) {
      return parsed.format('DD-MM-YYYY');
    } else {
      return item;
    }
  }
  const numeric = parseFloatSafe(item);
  if (numeric) {
    if (key.toLowerCase().includes('phone')) {
      return item;
    }
    if (key.toLowerCase().includes('perc')) {
      return formatPercent(numeric);
    }
    if (item > 10000) {
      return formatNumberWithSuffix(numeric);
    }
    return formatNumberWithSuffix(numeric);
  }
  if (typeof item == 'string' && item.includes('http')) {
    return formatUrl(item);
  }
  return item;
};

export const renderObjectTable = (items) => {
  if (!items) {
    return null;
  }

  // return JSON.stringify(items);
  const keys = Object.keys(items).filter((k) => !ignoreFacts.includes(k));
  return (
    <div className="w-full rounded overflow-x-auto  ">
      <table className="table-auto text-xs w-full h-full divide-y divide-line border-line ">
        <tbody className="divide-y divide-line text-xs ">
          {keys
            .filter((f) => items[f])
            .map((f, i) => (
              <tr
                key={`${f}-${i}`}
                className={cn({
                  'bg-gray-50 dark:bg-neutral-900 ': i % 2 != 0
                })}
              >
                <td className={cn('py-3 px-3 capitalize font-semibold')}>
                  {f.replaceAll('_', ' ')}
                </td>
                <td
                  className={cn(
                    'py-3 px-3 align-middle text-left',
                    tableEntryFormattingClasses(items[f])
                  )}
                >
                  {renderTableEntry(items[f], f)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export function renderJsonObject(json, nesting_level = 0) {
  if (!json) return null;

  const keys = Object.keys(json).sort((a, b) => {
    const aValueIsArray = Array.isArray(json[a]);
    const bValueIsArray = Array.isArray(json[b]);

    if (aValueIsArray && !bValueIsArray) {
      return 1; // a comes after b
    }
    if (!aValueIsArray && bValueIsArray) {
      return -1; // a comes before b
    }
    return 0; // no change in order
  });
  const values = Object.values(json);

  if (Array.isArray(json) && typeof json?.[0] == 'object') {
    return renderArrayTable(json);
  }
  if (Array.isArray(json) && typeof json?.[0] == 'string') {
    return (
      <ul className=" py-2">
        {json.map((item, idx) => (
          <li
            key={`list-item-${idx}`}
            className={cn('py-3 text-xs font-medium px-2', {
              'bg-gray-50 dark:bg-neutral-900 ': idx % 2 != 0
            })}
          >
            {item}
          </li>
        ))}
      </ul>
    );
  }

  return keys.map((key, index) => {
    // return sortArrayByPriority(Object.keys(json), jsonRank).map((key, index) => {
    const value = json[key];

    if (value === null || (Array.isArray(value) && value.length == 0))
      return null;

    // if (typeof value === 'string' && value.toLowerCase() == 'no data')
    //   return null;
    const excluded = [
      'system_prompt',
      'key',
      'prompt',
      'context',
      'question',
      'updates',
      'error'
    ];
    if (excluded.indexOf(key) != -1) return null;
    let content;

    if (Array.isArray(value) && typeof value?.[0] == 'string') {
      if (value.length === 0) return null;
      content = (
        <ul className="text-sm ">
          {value.map((item, idx) => (
            <li
              key={`list-item-${idx}`}
              className={cn('py-3 px-3 text-xs', {
                'bg-gray-50 dark:bg-neutral-900 ': idx % 2 != 0
              })}
            >
              {renderTableEntry(item, key)}
            </li>
          ))}
        </ul>
      );
    } else if (key == 'markdown') {
      const valueFixed = value.replace(/```markdown/g, '').replace(/```/g, '');
      content = (
        <div className="json-markdown">
          <ReactMarkdown>{valueFixed}</ReactMarkdown>
        </div>
      );
    } else if (Array.isArray(value) && typeof value?.[0] == 'object') {
      content = renderArrayTable(value);
    } else if (
      typeof value == 'object' &&
      !Array.isArray(value) &&
      Object.values(value).length > 2 &&
      ['string', 'number'].includes(typeof Object.values(value)?.[0])
    ) {
      content = renderObjectTable(value);
    } else if (
      (value && typeof value === 'string' && value.includes('http://')) ||
      (value && typeof value === 'string' && value.includes('https://'))
    ) {
      content = (
        <a className="underline text-blue-600" href={value} target="_blank">
          {value}
        </a>
      );
    } else if (typeof value === 'object' && value !== null) {
      content = (
        <Fragment>{renderJsonObject(value, nesting_level + 1)}</Fragment>
      );
    } else {
      content = (
        <p className="leading-relaxed text-sm whitespace-pre-wrap px-2">
          {value}
        </p>
      );
    }

    const ingoreHeaders = ['response', 'answer'];
    return (
      <div key={`${value} ${index}`} className="mb-5">
        {!ingoreHeaders.includes(key) &&
        (nesting_level > 1 || keys?.length > 1) ? (
          <h3 className="text-sm font-semibold capitalize px-2">
            {key.replaceAll('_', ' ')}
          </h3>
        ) : (
          ''
        )}
        {content}
      </div>
    );
  });
}
