import clsx from 'clsx';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { forwardRef } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { Fragment, Suspense } from 'react';

import Logo from '@/components/icons/Logo';
import useTradeNotifications from '@/hooks/useTradeNotifications';
import { supabase } from '@/utils/supabase-client';
import { useUser } from '@/utils/useUser';
import Avatar from 'boring-avatars';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
const DarkModeToggle = dynamic(
  () => import('@/components/utils/Widgets/DarkModeToggle'),
  { ssr: false }
);

import { useEffect, useState } from 'react';
const { publicRuntimeConfig } = getConfig();

import {
  NavigationItem,
  userNavigationItems
} from '@/components/data/navigation';
import { useNotification } from '@/hooks/useNotification';
import classNames from 'classnames';
import getConfig from 'next/config';
import Search from '../../Search/Search';
import {
  MobileNavigation,
  useIsInsideMobileNavigation,
  useMobileNavigationStore
} from './MobileNavigation';
import { Bell } from 'lucide-react';
// import { MobileSearch, Search } from './Search';

function TopLevelNavItem({ href, children }) {
  return (
    <li>
      <Link
        href={href}
        className="text-sm leading-5 text-zinc-600 transition hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white"
      >
        {children}
      </Link>
    </li>
  );
}

type HeaderProps = {
  className?: string;
  showSearch?: boolean;
};

const subscriptionButton = (sub) => {
  if (sub?.status == 'active') {
    return null;
  }
  return (
    <Link href={'/subscribe'} className="btn-default">
      Subscribe
    </Link>
  );
};

export const Header = forwardRef<HTMLDivElement, HeaderProps>(function Header(
  { className, showSearch = true },
  ref
) {
  const { isLoading, user, userDetails, subscription } = useUser();

  const [userNavigation, setUserNavigation] = useState<NavigationItem[]>([]);

  useTradeNotifications();

  const router = useRouter();

  function closeMobile() {
    useMobileNavigationStore.getState().close();
  }

  useEffect(() => {
    router.events.on('routeChangeStart', closeMobile);
    return () => {
      router.events.off('routeChangeStart', closeMobile);
    };
  }, []);

  useEffect(() => {
    setUserNavigation(userNavigationItems);
  }, [user, isLoading]);

  useEffect(() => {
    if (isLoading) return;
    if (typeof gtag !== 'undefined') {
      gtag('config', publicRuntimeConfig.GA_ID, {
        user_id: userDetails?.ga_uid
      });
    }
  }, [isLoading, userDetails?.ga_uid, userDetails?.id]);

  const { isOpen: mobileNavIsOpen } = useMobileNavigationStore();
  const isInsideMobileNavigation = useIsInsideMobileNavigation();

  const notification = useNotification();
  return (
    <motion.div
      ref={ref}
      className={clsx(
        'border-b border-line',
        className,
        'sticky w-full inset-x-0 top-0 z-50 flex h-14 items-center justify-between gap-12 px-2 transition sm:px-2  lg:px-3',
        !isInsideMobileNavigation &&
          'bg-white/80 dark:bg-black/80 backdrop-blur-xl  ',
        isInsideMobileNavigation ? 'bg-main-background' : ''
      )}
      // style={{
      //   '--bg-opacity-light': bgOpacityLight,
      //   '--bg-opacity-dark': bgOpacityDark
      // }}
    >
      <div
        className={clsx(
          'absolute inset-x-0 top-full h-px transition',
          (isInsideMobileNavigation || !mobileNavIsOpen) &&
            'bg-zinc-900/7.5 dark:bg-white/7.5'
        )}
      />
      <div className="hidden lg:flex h-14 ">
        <div className="flex-shrink-0 flex items-center mr-2">
          <Link href={'/home'} passHref legacyBehavior>
            <a
              className="cursor-pointer flex flex-row items-center"
              aria-label="Logo"
            >
              <Logo width={45} />
            </a>
          </Link>
        </div>
        <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8"></div>
      </div>
      {showSearch && (
        <div className=" hidden lg:block">
          <Search />
        </div>
      )}
      <div className="flex items-center gap-3 lg:hidden">
        <MobileNavigation />
        <Link href="/home" aria-label="Home">
          <Logo width={30} />
        </Link>
      </div>
      <div className="flex gap-2 md:gap-3 ">
        {/* <nav className="hidden md:block">
          <ul role="list" className="flex items-center gap-8">
            <TopLevelNavItem href="/">API</TopLevelNavItem>
            <TopLevelNavItem href="#">Documentation</TopLevelNavItem>
            <TopLevelNavItem href="#">Support</TopLevelNavItem>
          </ul>
        </nav> */}
        <div className=" block lg:hidden">
          <Search />
        </div>

        <div className=" items-center  hidden sm:flex">
          {userDetails && subscriptionButton(subscription)}
        </div>
        <Suspense fallback={<div></div>}>
          <DarkModeToggle />
        </Suspense>
        {userDetails && (
          <Link href="/profile/notifications" className="nav-button relative">
            {userDetails?.badge_count && userDetails?.badge_count > 0 ? (
              <span className=" bg-blue-600 rounded-full  top-0.5 right-1.5 text-[0.55rem]  absolute px-1 shadow-blue-700 font-bold text-white">
                {userDetails?.badge_count > 40
                  ? '40+'
                  : userDetails?.badge_count}
              </span>
            ) : null}
            <Bell
              className={classNames(
                'cursor-pointer',
                'w-5 h-5',
                'text-zinc-600 dark:text-zinc-400'
              )}
            />
          </Link>
        )}
        <div className="  flex   justify-end  gap-x-2 md:gap-x-3  ">
          {!user ? (
            !['/signin', '/signup'].includes(router.pathname) && (
              <Link legacyBehavior href="/signup">
                <a className="btn-default small">Sign Up</a>
              </Link>
            )
          ) : (
            <Menu as="div" className="relative">
              <div>
                <Menu.Button className="max-w-xs  flex items-center text-sm rounded-full  ">
                  <span className="sr-only">Open user menu</span>
                  {/* <img className="h-8 w-8 rounded-full" src={''} alt="" /> */}

                  <div className="rounded-full h-8 w-8 bg-gray-300 dark:bg-neutral-800 relative overflow-clip">
                    {userDetails?.avatar_url ? (
                      <div
                        style={{
                          backgroundImage: `url(${userDetails?.avatar_url})`,
                          backgroundSize: 'cover'
                        }}
                        className="w-full h-full"
                      />
                    ) : (
                      <Avatar
                        size={30}
                        name={user?.email}
                        variant="marble"
                        colors={[
                          '#92A1C6',
                          '#146A7C',
                          '#F0AB3D',
                          '#C271B4',
                          '#C20D90'
                        ]}
                      />
                    )}
                  </div>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 -500 ring-1 ring-black ring-opacity-5 focus:outline-none z-30 bg-main-background border border-line ">
                  {userNavigation
                    .filter((ul) => !ul.dev || userDetails?.show_dev_features)
                    .map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }: { active: boolean }) => (
                          <Link
                            href={item.href}
                            key={item.key}
                            onClick={async () => {
                              if (item.key === 'signout') {
                                const { error } = await supabase.auth.signOut();
                                if (error) {
                                  console.log(error);
                                  return;
                                }
                                router.push('/');
                                notification.showNotification({
                                  message: `You have been logged out`
                                });
                              }
                            }}
                            className={classNames(
                              'block px-4 py-2 text-sm text-neutral-900 dark:text-neutral-200 hover:bg-blue-500 hover:text-white'
                            )}
                          >
                            {item.name}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                </Menu.Items>
              </Transition>
            </Menu>
          )}
        </div>
      </div>
    </motion.div>
  );
});
