import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { useChatQuestion } from '@/hooks/useChatQuestion';
import { setPortfolio, updateStats } from '@/store/portfolio';
import { Features, RealtimeQuote, UserDetails } from '@/types/frontend/page';
import { RealtimeChannel, User } from '@supabase/supabase-js';
import { supabase } from './supabase-client';
import uiStore from '@/store/ui';
import listStore from '@/store/list';

type UserContextType = {
  accessToken: string | null;
  user?: User;
  userDetails?: UserDetails;
  isLoading: boolean;
  features: Features;
  isPortfolioLoading: boolean;
  subscription: Views<'msh_aggregated_subscriptions'> | null;
  session: any;
  watchlist?: RealtimeQuote[];
  chatApi: ReturnType<typeof useChatQuestion>;
  portfolioStore: ReturnType<typeof createPortfolioStore>;
  freeTrial?: Tables<'msh_free_trial'> | null | undefined;
  isLoadingSession: boolean;
};
import { chatState } from '@/service/chat';
export const UserContext = createContext<UserContextType | undefined>(
  undefined
);

export interface Props {
  [propName: string]: any;
}
import { createPortfolioStore } from '@/store/portfolio';
import dayjs from 'dayjs';
import { Tables } from '@/types/supabase';
import { Views } from '@/types/utils';
import useTracking from '@/hooks/useTracking';
import useApp from '../hooks/useApp';
import { useDebouncedCallback } from 'use-debounce';

const portfolioStore = createPortfolioStore();

const timeRequest = async (requestFunc, label) => {
  const result = await requestFunc();

  return result;
};

export const MyUserContextProvider = (props: Props) => {
  const [isLoadingData, setIsloadingData] = useState(false);
  const [isLoadingPortfolio, setIsLoadingPortfolio] = useState(true);
  const [isLoadingSession, setIsLoadingSession] = useState(true);
  const [userDetails, setUserDetails] = useState<UserDetails>();
  const [freeTrialData, setFreeTrialData] =
    useState<Tables<'msh_free_trial'> | null>(null);
  const [subscription, setSubscription] =
    useState<Views<'msh_aggregated_subscriptions'> | null>(null);
  const [user, setUser] = useState<User>();
  const chatApi = useChatQuestion(userDetails);
  const [loadingDataStarted, setLoadingDataStarted] = useState(false);
  const [watchlist, setWatchlist] = useState();

  const [refreshCounter, setRefreshCounter] = useState(0);
  const [session, setSession] = useState<any>();

  const [watchlistupdate, setWatchlistUpdate] = useState(0);

  const app = useApp();

  useEffect(() => {
    if (user?.id) {
      listStore.loadInitial();
    }
  }, [user?.id]);

  useEffect(() => {
    (async () => {
      const sessionData = await supabase.auth.getSession();
      setSession(sessionData);
      setIsLoadingSession(false);

      if (!sessionData.data.session && app && typeof document !== 'undefined') {
        const cookies = document.cookie.split('; ');
        const oldAuthCookieName = 'sb-mrktucfvjzricelaeltc-auth-token';
        const authTokenCookie = cookies.find(
          (row) => row.split('=')[0] === oldAuthCookieName
        );
        if (authTokenCookie) {
          const token = authTokenCookie.split('=')[1];

          const res = fetch(
            `${process.env.NEXT_PUBLIC_BASE_URL}/auth/app/legacy?token=${token}`,
            {
              method: 'GET',
              credentials: 'include'
            }
          )
            .then((res) => res.json())
            .then(async (data) => {
              if (data.status === 'ok') {
                const tokenResult = data.hashed_token;
                if (!tokenResult || typeof tokenResult !== 'string') {
                  return;
                }
                await supabase.auth.verifyOtp({
                  type: 'magiclink',
                  token_hash: data.hashed_token
                });
              }
            });
        }
        return;
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await supabase.auth.getUser();

      if (res.error) {
        setUser(undefined);
        setUserDetails(undefined);
        setSubscription(null);
        await supabase.auth.signOut();

        return;
      } else {
        if (res?.data?.user) {
          setUser(res?.data?.user);
        }
      }
    })();
  }, [supabase]);

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    const sub = supabase
      .channel(`realtime:public:msh_watchlists:${user.id}`)
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'msh_watchlists' },

        (payload) => {
          setWatchlistUpdate((prev) => prev + 1);
        }
      )
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'msh_lists' },

        (payload) => {
          listStore.handleRealtimeUpdate(payload as any);
        }
      )
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'msh_free_trial' },

        (payload) => {
          if (payload.new) {
            console.log('free trial update', payload);
            setRefreshCounter((prev) => prev + 1);
            // setFreeTrialData(payload.new);
          }
          // setWatchlistUpdate((prev) => prev + 1);
        }
      )

      .subscribe();
    listStore.enableSubscriptions();
    return () => {
      listStore.disableSubscriptions();
      supabase.removeChannel(sub);
    };
  }, [user?.id]);

  const trackEvent = useTracking();

  useEffect(() => {
    supabase?.auth?.onAuthStateChange((event, session) => {
      trackEvent('event', 'auth_state_change', {
        type: event,
        session: session
      });
      if (session?.user?.id) {
        // setUser(session?.user);
      } else {
        setUser(undefined);
        setUserDetails(undefined);
        setSubscription(null);
      }
    });
  }, []);

  // useEffect(() => {
  //   if (!user?.id) {
  //     return;
  //   }
  // const connectionSub = supabase
  //   .channel(`realtime:public:user_updates:${user?.id}`)
  //   .on(
  //     'postgres_changes',
  //     { event: '*', schema: 'public', table: 'msh_portfolio_connections' },
  //     (payload: any) => {
  //       if (payload.new) {
  //         portfolioStore.connections[payload.new.id] = payload.new as any;
  //       }
  //       if (payload.eventType == 'UPDATE') {
  //         if (payload.new.deleted) {
  //           portfolioStore.connections = Object.fromEntries(
  //             Object.entries(portfolioStore.connections).filter(
  //               ([k, v]) => k !== payload.new.id
  //             )
  //           );
  //         } else {
  //           portfolioStore.connections[payload.new.id] = payload.new as any;
  //         }
  //       }
  //     }
  //   )
  //   .subscribe();
  // return () => {
  //   supabase.removeChannel(connectionSub);
  // };
  // }, [supabase, user?.id]);

  useEffect(() => {
    supabase
      .from('msh_portfolio_connections')
      .select('*,msh_portfolios(*)')
      .neq('deleted', true)
      .then((res: any) => {
        if (res.data) {
          portfolioStore.connections = res.data.reduce(
            (acc, c) => ({ ...acc, [c?.id]: c }),
            {}
          );
        }
      });
  }, []);

  const chatSubscription = useRef<RealtimeChannel>();

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    const sup = (chatSubscription.current = supabase
      .channel(`realtime:public:msh_chat_messages:${user?.id}}`)
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'msh_chat_messages' },
        (payload) => {
          console.log('chat update', payload);
          const data = payload.new;
          if (payload.eventType == 'UPDATE') {
            const m = chatState.messages.find((m) => m.id == data['id']);
            if (!m) {
              return;
            }

            m.message = data['message'];
            m.status = data['status'];
            m.context = data['context'];
            m.is_sql = data['is_sql'];
            m.companies = data['companies'];

            // if (data['clusters']) {
            //   for (const path of Object.keys(data)) {
            //     chatState.messages.find((m) => m.id == data['id'])[path] =
            //       data[path];
            //   }
            // }
          }
          if (payload.eventType == 'INSERT') {
            const data = payload.new;
            if (data['chat_session_id'] == chatState.sessionId) {
              chatState.messages.push(data);
            }
          }
        }
      )
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'msh_chat_sessions' },
        (payload) => {
          console.log('chat update', payload);
          const data = payload.new;
          if (payload.eventType == 'UPDATE') {
            const m = chatState.sessions.find((m) => m.id == data['id']);
            if (!m) {
              return;
            }

            Object.assign(m, data);

            // if (data['clusters']) {
            //   for (const path of Object.keys(data)) {
            //     chatState.messages.find((m) => m.id == data['id'])[path] =
            //       data[path];
            //   }
            // }
            // also update session message:
            const session = chatState.sessions.find(
              (s) => s.id == data['chat_session_id']
            );
          }
          if (payload.eventType == 'INSERT') {
            const data = payload.new;
            const session = chatState.sessions.find((s) => s.id == data['id']);
            if (!session) {
              chatState.sessions.unshift(data);
            }
          }
        }
      )
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'msh_apple_transactions' },
        (payload) => {
          setRefreshCounter((prev) => prev + 1);
        }
      )
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'subscriptions' },
        (payload) => {
          setRefreshCounter((prev) => prev + 1);
        }
      )
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'users' },
        (payload) => {
          console.log('user update', payload);
          setUserDetails((old) => ({ ...old, ...payload.new }) as UserDetails);
        }
      )
      .subscribe());

    return () => {
      supabase.removeChannel(sup);
    };
  }, [supabase, user]);

  const loadPortfolioData = async () => {
    await supabase
      .rpc('get_own_and_shared_portfolios')
      .select(
        `id,name,is_auto_synced,last_sync_at,base_currency,current_balance,account_type,starting_balance,buying_power,is_paper,user_id,provider_name,privacy_mode,nlv,msh_users_portfolios(user_id),msh_portfolio_connections(*),msh_holdings_with_quotes(*),msh_holdings_paper_with_quotes(*),portfolios_daily_history(*),transactions:portfolios_transactions_agg(*),msh_transactions_full(*),portfolios_dividend_history(transaction_date,isin,msh_id,payout,description,currency,symbol),portfolios_options_history(*),portfolios_kpis_holdings_level(*),dividend_projection_by_portfolio(*),portfolios_transactions_count(*)`
      )
      // Todo: Re-enable this. Really need to fix the bug where deleted portfolios are still returned
      // .eq('msh_holdings_with_quotes.deleted', false)
      .order('nlv', { ascending: false, nullsFirst: false })
      .order('is_paper', { ascending: false })
      .order('value_amount', {
        foreignTable: 'msh_holdings_with_quotes',
        ascending: false
      })
      .order('transaction_time', {
        foreignTable: 'portfolios_transactions_agg',
        ascending: false
      })
      .order('transaction_time', {
        foreignTable: 'msh_transactions_full',
        ascending: false
      })
      .order('date', {
        foreignTable: 'portfolios_daily_history',
        ascending: true
      })

      .then((res) => {
        res?.data?.map((portfolio) => {
          if (portfolio.msh_holdings_paper_with_quotes?.length > 0) {
            portfolio.msh_holdings_with_quotes =
              portfolio.msh_holdings_paper_with_quotes;
            portfolio.msh_holdings_paper_with_quotes = [];
          }
          if (portfolio.msh_transactions_full.length > 0) {
            portfolio.transactions = portfolio.msh_transactions_full as any;
            portfolio.msh_transactions_full = [];
          }
          setPortfolio(portfolioStore, portfolio);
          updateStats(portfolioStore, portfolio?.id, { nlv: portfolio?.nlv });
        });
        setIsLoadingPortfolio(false);
      });
  };

  useEffect(() => {
    if (user?.id && !loadingDataStarted) {
      loadPortfolioData();
    }
  }, [user?.id]);

  const getUserDetails = () => supabase.from('users').select(`*`).single();
  const getFreeTrials = () =>
    supabase.from('msh_free_trial').select('*').single();
  const getSubscription = () =>
    supabase
      .from('msh_aggregated_subscriptions')
      .select('*')
      // .eq('subscription_type', 'apple')
      .limit(1)
      .single();

  useEffect(() => {
    supabase
      .from('msh_watchlists')
      .select('msh_id')
      .then((res: any) => {
        if (res.error) {
          return;
        }
        setWatchlist(res.data);
      });
  }, [watchlistupdate]);

  const getWatchlists = () => supabase.from('msh_watchlists').select('msh_id');

  const [features, setFeatures] = useState<Features>({
    isSubscriptionActive: false,
    isPaperTradingEnabled: true,
    isOptionsEnabled: false,
    isAllHoldingsEnabled: false,
    isDividendProjectionEnabled: false
  });

  useEffect(() => {
    const fetchFeatures = async () => {
      if (user) {
        setIsloadingData(true);
        Promise.allSettled([
          timeRequest(getUserDetails, 'getUserDetails'),
          timeRequest(getSubscription, 'getSubscription'),
          timeRequest(getWatchlists, 'getWatchlists'),
          timeRequest(getFreeTrials, 'getFreeTrials')
        ]).then((results) => {
          const userDetailsPromise = results[0];
          const subscriptionPromise = results[1];
          const watchlistPromise = results[2];
          const freeTrialPromise = results[3];

          if (userDetailsPromise.status === 'fulfilled') {
            if (userDetailsPromise.value.data?.ui_state) {
              uiStore.data = {
                ...uiStore.data,
                ...userDetailsPromise.value.data?.ui_state
              };
            }
            setUserDetails(userDetailsPromise.value.data as UserDetails);
          }
          let freeTrialActive = false;
          if (freeTrialPromise.status === 'fulfilled') {
            setFreeTrialData(freeTrialPromise.value.data);
            if (
              dayjs(freeTrialPromise?.value?.data?.free_trial_until).isAfter(
                dayjs()
              )
            ) {
              freeTrialActive = true;
            }
          }

          if (subscriptionPromise.status === 'fulfilled') {
            if (freeTrialActive || subscriptionPromise?.value?.data) {
              setFeatures((old) => ({
                ...old,
                isSubscriptionActive:
                  freeTrialActive ||
                  subscriptionPromise.value.data?.status === 'active',
                isOptionsEnabled:
                  freeTrialActive ||
                  subscriptionPromise.value.data?.status === 'active',
                isAllHoldingsEnabled:
                  freeTrialActive ||
                  subscriptionPromise.value.data?.status === 'active',
                isDividendProjectionEnabled:
                  freeTrialActive ||
                  subscriptionPromise.value.data?.status === 'active'
              }));
            }
            setSubscription(subscriptionPromise.value.data);
          }

          if (watchlistPromise.status === 'fulfilled')
            setWatchlist(watchlistPromise.value.data as any);

          setIsloadingData(false);
        });
      } else if (!user && !isLoadingData) {
        setUserDetails(undefined);
        setSubscription(null);
      }
    };
    fetchFeatures();
  }, [user?.id, refreshCounter]);

  const value = {
    isPortfolioLoading: isLoadingPortfolio,
    accessToken: '',
    user,
    session: session?.data?.session,
    userDetails,
    features,
    isLoading: false,
    subscription,
    watchlist,
    chatApi,
    portfolioStore,
    isLoadingSession: isLoadingSession,
    freeTrial: freeTrialData
  };

  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a MyUserContextProvider.`);
  }
  return context;
};
