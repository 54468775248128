import { TickerLinkQuote } from '@/types/frontend/page';
import Avatar from 'boring-avatars';
import getConfig from 'next/config';
import Image from 'next/legacy/image';
import { useState } from 'react';
const { publicRuntimeConfig } = getConfig();
export interface LogoProps {
  quote: TickerLinkQuote;
  className?: string;
  size?: number;
}

export const TickerLogo = ({ quote, className, size = 80 }: LogoProps) => {
  const [failed, setFailed] = useState(false);
  if (quote?.msh_id?.includes('US:FRED')) {
    return (
      <Image
        alt={quote?.logo || quote?.msh_id}
        src={`/data_source/fred.png`}
        width={size}
        height={size}
        layout="responsive"
        className={`rounded w-1 ${className} `}
      />
    );
  }
  return quote && quote?.logo && !failed ? (
    <Image
      alt={quote?.logo}
      src={`${publicRuntimeConfig.assetBaseUrl}/logo/${quote.logo}`}
      onError={(e) => {
        setFailed(true);
      }}
      width={size}
      // unoptimized={true}
      height={size}
      layout="responsive"
      className={`rounded w-full ${className}`}
    />
  ) : (
    <div
      className={`relative overflow-clip bg-gray-400 dark:bg-line  rounded w-full h-full flex justify-center items-center shadow p-0  ${className} ${
        !quote ? 'animate-pulse' : ''
      }`}
    >
      <h1
        className={`absolute font-bold text-slate-100 dark:text-primary text-[0.5rem] leading-none max-w-full`}
      >
        {quote && quote?.msh_id?.split(':')[1]?.slice(0, 4)}
      </h1>
      <div className="w-full h-full p-0 m-0 aspect-square flex-grow-0 ">
        <Avatar
          size={size}
          name={quote?.symbol || quote?.msh_id || 'loading'}
          variant="marble"
          square={true}
        />
      </div>
    </div>
  );
};
export default TickerLogo;
