import { useNotification } from '@/hooks/useNotification';
import { PageMeta } from '@/types/stripe/stripe';
import Head from 'next/head';
import { Fragment, ReactNode } from 'react';
import Sidebar from './Sidebar';
import { Header } from '@/components/ui/PageBlocks/Navigation/Header';
import { motion } from 'framer-motion';
import { useUser } from '@/utils/useUser';
import useApp from '@/hooks/useApp';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { Analytics } from '@vercel/analytics/react';
import classNames from 'classnames';
import { useUiStore } from '@/store/ui';
import InfoPanel from './ui/PageBlocks/InfoPanel';

interface Props {
  children: ReactNode;
  meta?: PageMeta;
}

export default function Layout({ children, meta: pageMeta }: Props) {
  const meta = {
    title: 'markets.sh',
    description: '',
    // cardImage: '/og.png',
    ...pageMeta
  };

  const notification = useNotification();
  const { user } = useUser();
  const isApp = useApp();
  const ui = useUiStore();

  return (
    <Fragment>
      <Head>
        <title>{meta.title}</title>
        <meta name="robots" content="follow, index" />
        <meta property="og:site_name" content={'markets.sh'} />
        <meta name="twitter:site" content="@markets_sh" />
      </Head>
      <div className=" bg-main-background  md:min-h-screen ">
        {!isApp && (
          <motion.header layoutScroll className="contents">
            <Header />
            <Sidebar />
          </motion.header>
        )}
        {notification.component}

        {/* <div className="lg:pl-14 lg:w-52 z-10"> */}
        <div
          className={classNames('z-10 ', {
            'lg:pl-56 ': ui.sidebar_pinned,
            'lg:pl-12 ': !ui.sidebar_pinned
          })}
        >
          <main className="">{children}</main>
          <SpeedInsights />
          <Analytics />
        </div>
      </div>

      <InfoPanel />
      {/* <FloatingChat /> */}
      {/* {!isApp && <Footer />} */}
    </Fragment>
  );
}
