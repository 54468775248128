import { FmpMetricsCombined } from '@/types/fmp';
import {
  MshCompanyNewsTopic,
  RealtimeQuote,
  TickerTag
} from '@/types/frontend/page';
import { Tables } from '@/types/supabase';
import { Views } from '@/types/utils';

export enum TickDirection {
  Up = 1,
  Down,
  Neutral
}

export enum BarTimeRange {
  day = 'day',
  two_week = 'two_week',
  week = 'week',
  month = 'month',
  year = 'year',
  five_year = 'five_year',
  ytd = 'ytd',
  all = 'all'
}

type MarketState = 'open' | 'closed' | 'extended-hours';

export interface CurrenciesStatus {
  crypto: MarketState;
  fx: MarketState;
}

export interface ExchangesStatus {
  nasdaq: string;
  nyse: string;
  otc: string;
}

// export interface DailyOpenClose {
//   afterHours: number;
//   close: number;
//   from: string;
//   high: number;
//   low: number;
//   open: number;
//   preMarket: number;
//   status: string;
//   symbol: string;
//   volume: number;
// }

export interface MarketHoliday {
  date: string;
  exchange: string;
  name: string;
  status: string;
  close?: string;
  open?: string;
}

export interface PoMarketStatus {
  afterHours: boolean;
  currencies: CurrenciesStatus;
  earlyHours: boolean;
  exchanges: ExchangesStatus;
  market: MarketState;
  serverTime: string;
}

export interface Publisher {
  name: string;
  homepage_url: string;
  logo_url: string;
  favicon_url: string;
}

export interface TickerNewsItem {
  id: string;
  publisher: Publisher;
  title: string;
  author: string;
  published_utc: string;
  article_url: string;
  tickers: string[];
  amp_url: string;
  image_url: string;
  description: string;
  keywords: string[];
}

export interface TickerNewsResponse {
  results: TickerNewsItem[];
  status: string;
  request_id: string;
  count: number;
  next_url: string;
}

export interface WhaleBaseInfo {
  filer_name: string;
  cik: string;
}

export interface ConsolidatedSnapshot {
  msh_id: string;
  ticker?: string | null;
  locale?: string | null;
  data_source: number;
  source_ref: string | null;
  tearsheet_filename?: string | null;
  quote: Partial<Tables<'msh_quotes_realtime'>>;
  snapshot?: TickerSnapshot;
  tags?: TickerTag[] | null;
  lastPrice: number | null;
  todaysChange: number;
  todaysChangePerc: number;
  tickDirection: TickDirection;
  // overview?: FmpProfile;
  dividends?: TickerDividend[] | null;
  splits?: TickerSplit[] | null;
  has_financials?: boolean;
  has_insiders?: boolean;
  has_ownership?: boolean;
  has_risk_insights?: boolean;
  has_options?: boolean;
  has_pdf?: boolean;
  has_senate_trades?: boolean;
  currency: string | null;
  topics?: MshCompanyNewsTopic[];
  metrics?: FmpMetricsCombined | null;
  parent_msh_id?: string | null;
  reportProfile?: Tables<'msh_company_report_profile'> | null;
  peers?: string[] | null;
  tabs?: string[] | null;
  // profile?: Partial<Tables<'fmp_profile'>> | null;
}

export interface Bar {
  v?: number;
  vw?: number;
  o?: number;
  c: number;
  h?: number;
  l?: number;
  t: number;
  n?: number;
}

export interface Day {
  c: number;
  h: number;
  l: number;
  o: number;
  v: number;
  vw: number;
}

export interface LastQuote {
  P: number;
  S: number;
  p: number;
  s: number;
  t: number;
}

export interface LastTrade {
  c: number[];
  sym: string;
  i: number;
  p: number;
  s: number;
  t: number;
  x: number;
}

export interface AggregateTick {
  ev: string;
  sym: string;
  v: number;
  av: number;
  op: number;
  vw: number;
  o: number;
  c: number;
  h: number;
  l: number;
  a: number;
  z: number;
  s: number;
  e: number;
}

export interface Min {
  av: number;
  c: number;
  h: number;
  l: number;
  o: number;
  v: number;
  vw: number;
}

export interface PrevDay {
  c: number;
  h: number;
  l: number;
  o: number;
  v: number;
  vw: number;
}

export interface TickerSplit {
  date: string;
  label: string;
  numerator: number;
  denominator: number;
}

export interface TickerDividend {
  dividend: number;
  adjDividend: number;
  label: string;
  date: string;
  recordDate: string;
  paymentDate: string;
  declarationDate: string;
}

export interface TickerSnapshot {
  day: Day;
  lastQuote: LastQuote;
  lastTrade: LastTrade;
  min: Min;
  prevDay: PrevDay;
  ticker: string;
  todaysChange: number;
  todaysChangePerc: number;
  updated: number;
}

export interface PolygonApiPagedResponse<T> {
  results: T[];
  status: string;
  request_id: string;
  next_url: string;
}
export interface SnapshotResponse {
  request_id: string;
  status: string;
  ticker: TickerSnapshot;
}

export interface AggregateResponse {
  ticker: string;
  queryCount: number;
  resultCount: number;
  adjusted: boolean;
  status: string;
  results: Bar[];
}

export interface TickerLastClose {
  T: string;
  v: number;
  vw: number;
  o: number;
  c: number;
  h: number;
  l: number;
  t: number;
  n: number;
}

export interface Address {
  address1: string;
  city: string;
  state: string;
}

export interface TickerDetail {
  active: boolean;
  address: Address;
  cik: string;
  composite_figi: string;
  currency_name: string;
  last_updated_utc: Date;
  locale: string;
  market: string;
  market_cap: number;
  name: string;
  outstanding_shares: number;
  phone_number: string;
  primary_exchange: string;
  share_class_figi: string;
  sic_code: string;
  sic_description: string;
  ticker: string;
  type: string;
}

export interface TickerDetailXResponse {
  count: number;
  request_id: string;
  results: TickerDetail;
  status: string;
}

export interface LastCloseResponse {
  ticker: string;
  queryCount: number;
  resultsCount: number;
  adjusted: boolean;
  results: TickerLastClose[];
  status: string;
  request_id: string;
  count: number;
}
